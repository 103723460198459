/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'gatsby'
import { navigate } from "gatsby"
import Image from 'gatsby-image'
import styled from 'styled-components'

const blogCategories = (props) => {
    
    
    return (
      <>
      <h1>We write words too.</h1>
      <CategoriesWrapper>
        <Link activeClassName="active" to="/read">Everything</Link>
        {props.categoryList.map(category => {
          let newCat = category.replace(/\s/g, '_')
          
          return (
            <Link activeClassName="active" to={`/read/${newCat.toLowerCase()}`}>{category}</Link>
          ) 
        })}
       </CategoriesWrapper>
      </>
    )
  }
export default blogCategories


const CategoriesWrapper = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  margin:48px 0;
  overflow-y: scroll;

  .active {
    border-bottom:3px solid #030303 !important;
    padding-bottom:3px !important;
    font-weight: 800;
  }
  
  a {
    font-size:1.1rem;
    margin:0 16px;
    white-space: nowrap;
    color: #030303 !important;
    font-weight: 500;
    padding-bottom:3px;
    border-bottom:3px solid white !important;

    :hover {
      color:#030303 !important;
      border-bottom:3px solid #030303 !important;
    }

    :nth-of-type(1) {
      margin-left:0;
    }
  }
`
