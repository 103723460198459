import React from 'react'
import Image from 'gatsby-image'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import './styled.css'


const HeroBlog = (props) => {
    
    return (
      <Row>
        <Col>
          <Image fluid={props.heroImage} />
        </Col>
        <Col lg="12">
          <Row>
            <Col lg="10" className="top-post pt-5 pb-5">
              <Row>
                <Col lg="5">
                  <h1 className="h2">{props.title}</h1>
                </Col>
                <Col lg="6">
                    <p>{props.description}</p>
                    <Link
                      className="styled__Link-lfEBUk iUBhrC font-weight-normal"
                      to={`/read/${props.slug}`}
                    >
                      {props.linkLabel}
                      <svg
                        className="styled__Arrow-fdeFVt fRPwvC"
                        width="14"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          stroke="#030303"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                          <path
                            d="M12.5 5.5H.5"
                            strokeLinecap="square"
                          ></path>
                        </g>
                      </svg>
                    </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }


export default HeroBlog
