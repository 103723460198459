/* eslint-disable */
import React, {useState} from 'react'
import Layout from '../components/Layout'
import Gettouch from '../components/Gettouch'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap'
import CardListing from '../components/BlogLising/CardsListing'
import HeroBlog from '../components/BlogLising/HeroBlog'
import BlogCategories from '../components/BlogLising/Categories'
import useFeaturedBlogPost from "../hooks/useFeaturedPost"


const BlogMainPage = props => {
  const cards = props.data.allContentfulBlogPost.edges[0].node.cardBlogListing
  const slug = props.data.allContentfulBlogPost.edges[0].node.slug
  const { currentPage, numPages, categoryList } = props?.pageContext
  const cardsData = props.data.allContentfulBlogPost.edges
  const featuredPost = useFeaturedBlogPost()
 
  const featured_post_hero = currentPage === 1 && featuredPost && (
    <HeroBlog
      title={featuredPost.cardBlogListing[0].titleCard}
      linkLabel={"Read more"}
      description={featuredPost.cardBlogListing[0].descriptionCard.descriptionCard}
      heroImage={featuredPost.cardBlogListing[0].imageCard.fluid}
      slug={featuredPost.slug}
    />
  )
  return (
    <Layout>
      <Helmet>
        <title>Urbian | Read</title>
      </Helmet>
      <Container className="mb-5 blog_post content-wrap">
        <BlogCategories categoryList={categoryList}></BlogCategories>
        {featured_post_hero}
        <CardListing  cardsData={cardsData} slug={slug}></CardListing>
      </Container>
      <Gettouch></Gettouch>
    </Layout>
  )
}


export default BlogMainPage


export const contentfulBlogPageQuery = graphql`
  query blogMainPageWpQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(skip: $skip, limit: $limit, sort: {order: DESC, fields: createdDate}, filter: {tags: {nin: ["Featured Post"]}}) {
      edges {
        node {
          slug
          pageTitle
          createdDate
          cardBlogListing {
            id
            titleCard
            imageCard {
              fluid(maxWidth: 800){
                ...GatsbyContentfulFluid_withWebp
              }
              id
            }
            descriptionCard {
              descriptionCard
              childMarkdownRemark {
                id
              }
            }
          }
          categories
        }
      }
    }
  }
`
